import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { BiHappyAlt } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import Popup from "reactjs-popup";
import Loader from "./Loader";
import _axios from "../axios";
import buildLink from "../urls";
const ClaimPointsPopup = ({ isOpen, onClose }) => {
  const [Loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [points, setPoints] = useState(0);
  const [minclaimpoints, setMinclaimpoints] = useState(0);
  const [points_money_equivalant, setPointsMoneyEquivalant] = useState(0);
  const claimpointsRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [errorMessagee, setErrorMessagee] = useState("");
  const [currencysymbol, setCurrencySymbol] = useState("");

  useEffect(() => {
    getClaimPoints();
  }, []);

  const getClaimPoints = async () => {
    try {
      setLoading(true);
      const url = buildLink("claimpoints");
      const resp = await _axios.get(url);

      if (resp.data.success) {
        setPoints(resp.data.data.points);
        setMinclaimpoints(resp.data.data.min_points_to_claim);
        setPointsMoneyEquivalant(resp.data.data.points_money_equivalant);
        setCurrencySymbol(resp.data.data.currency_symbol);

      } else {
        setMessage(resp.data.message || "An error occurred.");
        toast(resp.data.message || "An error occurred", { type: "error" });
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      toast("An error occurred", { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const submitclaimpoints = async () => {
    try {
      setLoading(true);

      const obj = {
        points_to_claim: claimpointsRef.current.value,
      };
      const url = buildLink("claimpoints");
      const resp = await _axios.post(url, obj);

      if (resp.data.success) {
        setLoading(false);
        setInputValue("");
        toast("Points Claimed Successfully", { type: "success" });
        getClaimPoints();
      } else {
        setLoading(false);
        toast(resp.data.message, { type: "error" });
      }
    } catch (error) {
      setLoading(false);
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };



  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value && value < minclaimpoints) {
      setErrorMessagee(`Minimum claimable points are ${minclaimpoints}.`);
    } else {
      setErrorMessagee("");
    }
  };

  const usdEquivalent = inputValue * points_money_equivalant;

  return (
    <Popup
      open={isOpen}
      onClose={onClose}
      modal
      closeOnDocumentClick
      overlayStyle={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-full max-w-[600px] px-4 sm:px-6">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full h-auto flex flex-col justify-between max-sm:p-0">
          {message ? (
            <p className="text-dbase font-semibold text-2xl">{message}</p>
          ) : (
            <div className="flex mx-auto my-6 flex-col w-full space-y-4 p-12">
              <h1 className="text-2xl text-start font-semibold">
                Claim Your Points
              </h1>

              <div className="flex flex-col sm:flex-row sm:space-x-4 justify-center items-center sm:space-y-0 space-y-4">
                <div className="bg-gradient-to-r from-[rgba(204,0,0,1)] to-[rgba(204,0,0,0.5)] text-white border border-dgreyZoom text-lg shadow-md rounded-md px-5 py-2 w-full max-w-[300px] flex flex-col items-start space-y-2 max-sm:space-y-0">
                  <span className="text-lg">Total Points:</span>
                  <div className="flex flex-row justify-center gap-2">
                    <span className="text-white text-2xl">{points}</span>
                    <span className="text-2xl my-auto">Pts</span>
                  </div>
                </div>

                <div className="bg-gradient-to-r from-[rgba(204,0,0,1)] to-[rgba(204,0,0,0.5)] text-white border border-dgreyZoom text-lg shadow-md rounded-md px-5 py-2 w-full max-w-[300px] flex flex-col items-start space-y-2 max-sm:space-y-0">
                  <span className="text-lg">Points Worth:</span>
                  <div className="flex flex-row justify-center gap-2">
                    <span className="text-white text-2xl">
                      {(points * points_money_equivalant).toFixed(2)}
                    </span>
                    <span className="text-2xl my-auto">{currencysymbol}</span>
                  </div>
                </div>
              </div>
              <p className="text-black text-lg font-semibold text-center border border-dyellow px-4 py-2 rounded-md flex items-center justify-center">
                <FiAlertTriangle className="mr-2 text-dyellow" />
                <span className="text-sm">
                  Minimum Claimable Points Are {minclaimpoints}
                </span>
              </p>

              <div className="flex flex-row gap-2 justify-center items-center w-full">
                <input
                  type="number"
                  className={`border p-3 rounded-md ${
                    inputValue < minclaimpoints && inputValue !== ""
                      ? "border-dbase"
                      : inputValue === ""
                      ? "border-dbase"
                      : "border-dgreen"
                  } focus:outline-none w-3/4 h-[50px]`}
                  ref={claimpointsRef}
                  placeholder="Enter Points Count"
                  value={inputValue}
                  onChange={handleInputChange}
                />
                <button
                  onClick={submitclaimpoints}
                  className={`border rounded-md p-2 w-1/4 h-[50px] ${
                    claimpointsRef.current?.value &&
                    claimpointsRef.current?.value >= 1000
                      ? "bg-dbase text-white hover:bg-dbase1"
                      : "bg-dsearchGrey cursor-not-allowed"
                  } flex items-center justify-center`}
                  disabled={
                    !(
                      claimpointsRef.current?.value &&
                      claimpointsRef.current?.value >= 1000
                    ) || Loading
                  }
                >
                  {Loading ? (
                    <div className=" w-7 h-7 relative my-auto">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      Claim <BiHappyAlt />
                    </>
                  )}
                </button>
              </div>
              <div className="flex items-center space-x-2">
                <label className="text-dblack font-semibold text-xl">
                  Points Worth:
                </label>
                <p className="bg-dgreen2 bg-opacity-30 text-dgreen px-3 py-1">
                  {currencysymbol} {usdEquivalent}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default ClaimPointsPopup;
